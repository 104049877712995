#responsive-helper{
  width: 50px;
  height: 50px;
  position: fixed;
  left:0;
  bottom: 0;
  background: black;
  z-index: 200000;
  text-align: center;
  line-height: 50px;
  font-size: 1.5rem;
  &:before{
    content:"";
    color:white;
  }
  @include media-breakpoint-down(sm) {
    background: red;
    &:before{
      content:"XS";
    }
  }
  @include media-breakpoint-up(sm) {
    background: orange;
    &:before{
      content:"SM";
    }
  }
  @include media-breakpoint-up(md) {
    background: yellow;
    &:before{
      color: orange;
      content:"MD";
    }
  }
  @include media-breakpoint-up(lg) {
    background: green;
    &:before{
      color:white;
      content:"LG";
    }
  }
  @include media-breakpoint-up(xl) {
    background: blue;
    &:before{
      color:white;
      content:"XL";
    }
  }
}
