@import "stylesheets/variables";

/**
  * Bootstrap
 */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities";

@import "./stylesheets/responsive-helper";

html, body{
  height: 100vh;
}

video{
  width: 100%;
  object-fit: cover;
  object-position: center 50%;
  height: 100vh;
  display: block;
  margin: auto;
  transition: all 0.3s ease;
  transition-delay: 5s;
  &.playing{
    object-position: center 100%;

    & ~ #button-order{
      animation-name:buttonorder;
      animation-delay: 9s;
      animation-fill-mode: both;
      animation-duration: 0.3s;
    }
    & ~ #button-facebook{
      animation-name:buttonorder;
      animation-delay: 9s;
      animation-fill-mode: both;
      animation-duration: 0.3s;
    }
  }
}

#button-opening{
  position: fixed;
  width: 17%;
  padding-top: 17%;
  left: 50%;
  top: 48%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @include media-breakpoint-down(xl) {
    width: 21%;
    padding-top: 21%;
  }
  @include media-breakpoint-down(lg) {
    width: 25%;
    padding-top: 25%;
  }
}

#button-order{
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  height: 75vh;
  background-color: transparent;
  mix-blend-mode: overlay;
  opacity: 1;
  cursor: pointer;
  pointer-events: none;
  width: 100%;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  &:hover{
    background-color: #364755;
  }
  span{
    display: none;
  }
}

#button-facebook{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25vh;
  background-color: transparent;
  mix-blend-mode: overlay;
  opacity: 1;
  cursor: pointer;
  pointer-events: none;
  transition: all 0.3s ease;
  &:hover{
    background-color: #364755;
  }
  span{
    display: none;
  }
}

@keyframes buttonorder {
  0%{
    pointer-events: none;
  }
  100% {
    pointer-events: visible;
  }
}


.small-device{
  display: none;
}
.brand{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -70%);
  filter: drop-shadow(0 0 0.3rem white);
}

.btn-order{
  position: fixed;
  background-color: #364756;
  border-radius: 20px;
  display: table;
  padding: 0.5rem 2rem;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 75px);
  white-space: nowrap;
  &:hover{
    color: white;
  }
  @include media-breakpoint-down(sm){
    font-size: 13px;
  }
}

@include media-breakpoint-down(md) {
  .small-device{
    display: block;
  }
  video{
    display: none;
  }
  #button-facebook{
    display: none;
  }
  #button-order, #button-opening{
    display: none;
  }
  body{
    background-image: url("../images/MEE_animation_boite_1.jpg");
    background-position: center center;
    background-size: cover;
  }
}
